<template>
  <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
    <section id="dashboard">
      <div>
        <b-row class="">
          <b-col xl="6" md="6">
            <b-card class="card-statistics p-1" style="min-height: 200px">
              <div class="d-flex justify-content-between">
                <h4 class="font-weight-bolder">Back Up Data</h4>
                <b-card-text class="update-text font-small-2 mb-0">
                  <!-- Updated 1 day ago -->
                </b-card-text>
              </div>
              <b-card-body class="">
                <b-row class="info-wrapper">
                  <b-col xl="12" class="">
                    <b-media>
                      <b-media-body class="my-auto color0">
                        <h4 class="font-weight-bolder mb-0" style="color: green; background-color: white">
                          <b-button variant="primary" @click="downloadBackupStorage">
                            Download Backup Storage
                          </b-button>
                        </h4>
                        <br />
                        <h4 class="font-weight-bolder mb-0" style="color: green; background-color: white">
                          <b-button variant="primary" @click="downloadDatabaseBackupJSON">
                            Download Database Backup JSON
                          </b-button>
                        </h4>
                        <br />
                        <!-- <b-button variant="primary" @click="downloadDatabaseBackupSQL">
                          Download Database Backup SQL
                        </b-button> -->
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col xl="12" md="12">
          <b-card class="card-statistics p-1" style="min-height: 200px">
            <b-card-body class="">
              <ApexBarChart :options="productBarChartOptions" :series="productBarSeries" :date_from="date_from"
                :date_to="date_to" @date-change="handleDateChange" />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="12" md="12">
          <b-card class="card-statistics p-1" style="min-height: 200px">
            <b-card-body class="">
              <ApexBarChartCategory :options="productBarChartCategoryOptions" :series="productBarChartCategorySeries"
                :date_from="date_from" :date_to="date_to" @date-change="handleDateChange" />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import VueApexCharts from "vue-apexcharts";
import ability from "@/libs/acl/ability";
import { $themeColors } from '@themeConfig'
import ApexBarChart from './ApexBarChart.vue'
import ApexBarChartCategory from './ApexBarChartCategory.vue'

export default {
  components: {
    VueApexCharts,
    ApexBarChart,
    ApexBarChartCategory,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
    BOverlay,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    downloadDatabaseBackupJSON() {
      this.loading = true;
      console.log("Downloading database backup...");
      store.dispatch("app/downloadDatabaseBackupJSON")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.json'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    downloadDatabaseBackupSQL() {
      this.loading = true;
      console.log("Downloading database backup...");
      store.dispatch("app/downloadDatabaseBackupSQL")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.sql'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    downloadBackupStorage() {
      this.loading = true;
      console.log("Downloading backup storage...");
      store.dispatch("app/downloadBackupStorage")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' })); // Specify the MIME type
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.zip'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    handleDateChange(val) {
      // Handle string format "YYYY-MM-DD to YYYY-MM-DD"
      if (typeof val === 'string') {
        const dates = val.split(' to ');
        if (dates.length !== 2) {
          console.error('Invalid date range format:', val);
          return;
        }
        this.date_from = dates[0];
        this.date_to = dates[1];
      } else if (Array.isArray(val) && val.length === 2) {
        // Keep existing array handling as fallback
        this.date_from = val[0];
        this.date_to = val[1];
      } else {
        console.error('Invalid date range format:', val);
        return;
      }

      if (!this.date_from || !this.date_to) {
        console.error('Invalid date values:', val);
        return;
      }
      this.fetchDashboardData(this.date_from, this.date_to);
    }
  },
  setup() {
    const customerData = ref([]);
    const salesData = ref([]);
    const couponData = ref([]);
    const mostViewedProducts = ref([]);
    const mostViewedCategories = ref([]);
    const productBarChartOptions = ref({});
    const productBarSeries = ref([]);
    const productBarChartCategoryOptions = ref({});
    const productBarChartCategorySeries = ref([]);
    const year = ref(new Date().getFullYear());
    const date_from = ref(year.value + '-01-01');
    const date_to = ref(year.value + '-12-31');

    const fetchDashboardData = () => {
      store.dispatch("app/fetchDashboardData", { date_from: date_from.value, date_to: date_to.value })
        .then((response) => {
          customerData.value = response.data.customers;
          salesData.value = response.data.sales;
          couponData.value = response.data.coupons;
          mostViewedProducts.value = response.data.most_viewed_products;
          mostViewedCategories.value = response.data.most_viewed_categories;

          if (mostViewedProducts.value.length > 0) {
            // Bar Chart data
            // chartOptions:
            productBarChartOptions.value = {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              colors: $themeColors.primary,
              plotOptions: {
                bar: {
                  horizontal: false,
                  barHeight: '30%',
                  endingShape: 'rounded',
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: mostViewedProducts.value.map((product) => product.name),
              },
              yaxis: {
                // opposite: isRtl,
              },
            }

            productBarSeries.value = [
              {
                data: mostViewedProducts.value.map((product) => product.total_views),
              },
            ];
          } else {
            productBarChartOptions.value = {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              colors: $themeColors.primary,
              plotOptions: {
                bar: {
                  horizontal: false,
                  barHeight: '30%',
                  endingShape: 'rounded',
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: [],
              },
              yaxis: {
                // opposite: isRtl,
              },
            }

            productBarSeries.value = [
              {
                data: [],
              },
            ];
          }

          if (mostViewedCategories.value.length > 0) {
            // Bar Chart data
            // chartOptions:
            productBarChartCategoryOptions.value = {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              colors: $themeColors.primary,
              plotOptions: {
                bar: {
                  horizontal: false,
                  barHeight: '30%',
                  endingShape: 'rounded',
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: mostViewedCategories.value.map((category) => category.name),
              },
              yaxis: {
                // opposite: isRtl,
              },
            }

            productBarChartCategorySeries.value = [
              {
                data: mostViewedCategories.value.map((category) => category.total_views),
              },
            ];
          } else {
            productBarChartCategoryOptions.value = {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              colors: $themeColors.primary,
              plotOptions: {
                bar: {
                  horizontal: false,
                  barHeight: '30%',
                  endingShape: 'rounded',
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: [],
              },
              yaxis: {
                // opposite: isRtl,
              },
            }

            productBarChartCategorySeries.value = [
              {
                data: [],
              },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      fetchDashboardData();
    });

    return {
      ability,
      customerData,
      salesData,
      couponData,
      mostViewedProducts,
      mostViewedCategories,
      productBarChartOptions,
      productBarSeries,
      productBarChartCategoryOptions,
      productBarChartCategorySeries,
      date_from,
      date_to,
      fetchDashboardData,
    };
  },
};
</script>

<style lang="scss" scoped>
.primary {
  color: red;
}
</style>
