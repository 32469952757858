<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25">
          Most Viewed Products
        </b-card-sub-title>
        <b-card-title class="font-weight-bolder">
          <!-- $74,382.72 -->
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr v-model="rangePicker" :config="dateRangeConfig"
          class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD"
          @input="handleDateChange" />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts v-if="true" type="bar" height="350" :options="options" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    date_from: {
      type: String,
      required: true,
    },
    date_to: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rangePicker: [this.date_from, this.date_to],
    }
  },
  methods: {
    handleDateChange(val) {
      this.$emit('date-change', val);
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>